<template>
  <div class='list'>
    <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onAdd="onAdd"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
    ></list-template>
    <!-- 删除面板 -->
    <el-dialog :visible.sync='deleteShow' width='30%'>
      <span>删除后,相关数据将清除,真的要删除吗?</span>
      <span slot='footer' class='dialog-footer'>
				<el-button @click='deleteShow = false'>点错了</el-button>
				<el-button type='primary' @click='confirm'>删除</el-button>
			</span>
    </el-dialog>

    <!-- 导入面板 -->
    <el-dialog :title='duploadTitle' :visible.sync='duploadShow' width='640rem'>
      <div style='line-height: 38rem'>
        <span>导入阅卷数据需下载模板,正确填写模板内的信息后,可导入到系统中</span>
        <div><span>科目:</span>&nbsp;&nbsp;&nbsp;&nbsp;{{ subjectStr }}</div>
        <div>
          <span>点此:</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span @click='download' style='color: #3390ff; cursor: pointer'>下载考试成绩模板</span>
        </div>
        <div>
          <span>选择年级:</span>&nbsp;&nbsp;&nbsp;&nbsp;
          <el-select :popper-append-to-body='false' ref='select' @visible-change='onVisible' v-model='grade' placeholder='请选择' @change='gradeChange'>
            <el-option v-for='item in gradeArr' :key='item.value' :label='item.label' :value='item.value'></el-option>
          </el-select>
        </div>
      </div>
      <br/>
      <el-upload
          class='upload-demo'
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          :action="$_axios.defaults.baseURL + '/ranking/score-import'"
          :show-file-list='false'
          :headers="{ authorization: 'Bearer ' + $store.getters.token }"
          :multiple='false'
          :on-success='onSuccess'
          :on-error='onError'
          :name="'myfile'"
          :data='{
					exam_id,
					subject_id,
					grade_id
				}'
      >
        <el-button style='margin-top: 10rem'>点此上传</el-button>
        <br/>
        <br/>
      </el-upload>
      <p style='color: red; font-size: 14rem' v-if='failhintshow'>导入失败原因:{{ failhint }}</p>
    </el-dialog>
  </div>
</template>

<script>
import {examrepository, examSubject, getExam, grade} from './api'
// import { tableListMixin } from '@/utils/mixins'
import {mapState} from 'vuex'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  // mixins: [tableListMixin],
  computed: {
    ...mapState(['page'])
  },
  data() {
    return {
      loading:true,
      search: null,
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: [],
      // 搜索条件
      subject_id: '', // 教学科目
      subject: '', // 教学科目
      examId: '', // 考试名称
      exam_id: '', //
      deleteShow: false, // 删除弹窗显隐控制
      duploadTitle: '', // 上传面板标题
      gradeArr: [], // 年级数据
      grade: '',
      subjectStr: '', // 科目显示文字
      grade_id: '', // 上传文件额外参数
      duploadShow: false, // 导入文件弹窗显隐控制
      failhint: '', // 导入失败原因
      failhintshow: false, // 导入失败原因显隐控制
      searchConfig: [
        {
          placeholder: '筛选考试名称',
          prop: 'exam_id',
          tag: 'select',
          label: 'exam_name',
          value: 'exam_id',
          options: [],
          change: (value, search) => {
            this.searchConfig[1].options = []
            if(search.subject_id) {
              search.subject_id = ''
            }
            examSubject(value).then(result => {
              this.searchConfig[1].options = JSON.parse(JSON.stringify(result.data.data).replace(/id/g, 'value').replace(/subject_name/g, 'label').replace(/child/g, 'children')
              )
            })
          }
        },
        {
          placeholder: '筛选考试科目',
          prop: 'subject_id',
          tag: 'cascader',
          options: [],
          change: (value) => {
            this.subjectChange(value)
          }
        },
      ],
      tableConfig: [
        {
          prop: 'exam_name',
          label: '考试名称'
        },
        {
          prop: 'subject_name',
          label: '考试科目'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          width: 200,
          handle: true,
          buttons: row => {
            if($store.getters.userInfo.userinfo.id != row.creator_id) {
              return [{ type:"view", text:"查看" }]
            }
            return [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
          }
        }
      ],
    }
  },
  methods: {
    handleClick(row, config) {
      const { type } = config
      if(type == 'view') {
        this.$router.push({path: './look', query: { id:  row.id }})
      } else if(type == 'edit') {
        this.$router.push({path: './edit', query: { id:  row.id }})
      } else if(type == 'delete') {
        this.deleteRow(row)
      } else {
        this.ImportBtn(row)
      }
    },
    onVisible(e) {
      setTimeout(() => {
        // const el = this.$refs.select.$refs.popper.$el;
        // el.style.cssText += "z-index:4000 !important;"
      }, 20)

    },
    gradeChange(val) {
      this.grade_id = val
    },
    // 下载模板
    download() {
      this.$_axios
          .post(
              '/ranking/score-template',
              {
                exam_id: this.exam_id,
                subject_id: this.subject_id
              },
              {
                responseType: 'blob'
              }
          )
          .then(res => {
            const filename = this.duploadTitle + this.subjectStr + '.xlsx'
            const blob = new Blob([res.data])
            let downloadElement = document.createElement('a')
            let href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            downloadElement.download = decodeURIComponent(filename)
            document.body.appendChild(downloadElement)
            downloadElement.click()
            document.body.removeChild(downloadElement)
            window.URL.revokeObjectURL(href)
          })
          .catch(err => {
          })
    },
    subjectChange(val) {
      this.subject = val[1]
    },
    // 表格搜索按钮
    onSearch(search) {
      let params = {
        page: this.page
      }
      if (!!search) {
        if (search.exam_id == '' && search.subject_id == '') {
          this.$message.warning('搜索内容不能为空!')
          return
        } else params = Object.assign({}, params, search)
      }
      this.loading = true
      examrepository(params).then(res => {
        this.tableData = res.data.data
        let {headers} = res
        let {total} = this.$tools.getPaginationInfo(headers)
        this.total = total
      }).finally(() => {
        this.loading = false
      })
    },
    // 重置按钮
    onReset() {
      this.search = null
      this.onSearch((this.$store.commit('setPage', 1), (this.examId = ''), (this.subject = '')))
    },
    onAdd() {
      this.$router.push('./add')
    },
    deleteRow(row) {
      this.deleteShow = true
      this.dormitoryId = row.id
    },
    // 删除弹窗确定按钮
    confirm() {
      this.deleteShow = false
      this.$_axios.delete('exam-repository/' + this.dormitoryId).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    },
    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.onSearch()
    },
    // 文件上传成功
    onSuccess(res) {
      if (res.error.errorCode != 0) {
        this.failhint = res.error.errorUserMsg
        this.failhintshow = true
        return
      } else {
        this.failhintshow = false
        this.$message.success('导入成功')
      }
    },
    // 文件上传失败
    onError() {
      this.$message.error('上传出错请重试')
    },
    ImportBtn(row) {
      this.grade = ''
      this.grade_id = ''
      this.failhintshow = false
      this.subjectStr = row.subject_name
      this.exam_id = row.exam_id
      this.subject_id = row.subject_id
      this.duploadTitle = row.exam_name
      this.duploadShow = true
      this.gradeArr = []
      grade(row.exam_id).then(res => {
        this.gradeArr = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'))
      })
    }
  },

  activated() {
    this.onSearch()
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.onSearch()
  },
  created() {
    getExam().then(res => {
      this.searchConfig[0].options = res.data.data
    })
  }
}
</script>

<style scoped lang='scss'>
.list {
  padding: 24rem 0 0 24rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }

}

span {
  cursor: pointer;
}

</style>
